import { logger } from "@/logger";
import { useSession } from "next-auth/react";

export default function Home() {
  // get session from next-auth
  logger.debug("Home");

  const { data: session } = useSession();
  logger.debug({ session });

  return (
    <div
      className={`flex flex-col items-center justify-center flex-1 min-h-screen`}
    >
      <h1 className="text-4xl font-bold text-center mb-8">
        Every good project starts with a good plan
      </h1>
    </div>
  );
}
