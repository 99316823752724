import pino from "pino";

// if server side rendering, use pino-pretty
if (typeof window === "undefined") {
  const transport = pino.transport({
    target: "pino-pretty",
    options: { destination: 1 }, // use 2 for stderr
  });
  pino(transport);
}

export const logger = pino({
  level: "debug",
});
